.Checkout {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 72px 100px;
}

.checkoutContainer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 72px;
}

.CheckoutCartInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fdfdfd;
    padding: 40px 32px;
    box-shadow: 0px 5px 10px 0px #0000001A;
    align-self: flex-start;
    color: var(--secondary);
}

.CheckoutItem {
    display: flex;
    /* justify-content: flex-start; */
    /* align-items: center; */
    gap: 8px;
    padding: 16px 0 32px;
    border-bottom: 1px solid #969696;
    text-align: left;
}

.checkoutItemImg {
    height: 110px;
    width: 150px;
    box-shadow: 0px 5px 10px 0px #0000001A;
}

.checkoutItemImg>img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    object-position: center;
}

.checkoutItemInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 8px;
    gap: 2px;
    font-size: 16px;
    flex-grow: 1;
}

.Checkout .input{
    border: 1px solid white;
}

.checkoutCartInfoGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: left;
    gap: 8px;
    border-bottom: 1px solid #969696;
    padding: 16px 0 24px;
    margin: 4px 0 0;
}

@media only screen and (max-width: 960px) {
    .Checkout {
        padding: 32px 20px 100px;
        gap: 32px;
    }
    
    .checkoutContainer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 72px;
    }
    
    .Checkout .BA_container {
        order: 2;
    }

    .Checkout .CheckoutCartInfo{
        order: 1;
    }
}