.LoveLetters {
    display: grid;
    grid-template-columns: 49% 50%;
    gap: 1%;
    padding: 72px 72px 0px;
    min-height: calc(100vh);
    max-height: calc(100vh);
    margin: 40px 0;
    position: relative;
}

.HomeLLImgDiv {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 100%;
    max-height: calc(100% - 40px);
}

.HomeLLImgDiv>img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}

.homeLLContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    text-align: left;
}

.LLText{
    font-size: 48px;
    color: white;
    align-self: flex-end;
    line-height: 48px;
    max-width: 360px;
    margin: 0 auto;
}

.iconLL{
    align-self: flex-start;
    flex-grow: 1;
    min-width: 94px;
    margin-top: -16px;
}


.swiper{
    overflow-y: visible !important;
    overflow-x: hidden !important;
    max-width: 100%;
    display: flex !important;
    margin: 0;
}

.LoveLetters .swiper-slide{
    display: flex !important;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 48px;
    height: 100%;
    text-align: left;
    padding-bottom: 28px;
}

.homeLLHeader{
    display: flex;
    gap: 16px;
    width: 100%;
    padding-left: 32px;
}

.LoveLetters .swiper-pagination{
    /* transform: translateY(10px); */
}

@media only screen and (max-width: 768px) {
    .LoveLetters {
        padding: 72px 20px 0px;
        min-height: unset;
        max-height: unset;
    }
    
    .LLText{
        font-size: 32px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 640px) {
    .LoveLetters {
        grid-template-columns: 1fr;
        gap: 1%;
        padding: 72px 20px 0px;
        min-height: calc(100vh);
        max-height: unset;
    }
    
    .HomeLLImgDiv {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: calc(100% - 20px);
        max-height: calc(100% - 40px);
    }
    
    .homeLLContent {
        gap: 40px;
        text-align: center;
    }
    
    .LLText{
        font-size: 32px;
        align-self: center;
        line-height: 40px;
        order: 2;
        max-width: 440px;
    }
    
    .iconLL{
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    
    .swiper{
        /* overflow-y: visible !important; */
        /* overflow-x: hidden !important; */
        max-width: calc(100vw - 40px);
        /* display: flex !important; */
        /* margin: 0; */
    }
    
    .swiper-slide{
        text-align: center;
    }

    .homeLLHeader{
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        width: 100%;
        padding-left: 0px;
    }
}