.CEG {
    display: grid;
    grid-template-columns: 105fr 90fr;
    gap: 32px;
    padding: 80px 72px 0px;
    min-height: calc(100vh);
    max-height: calc(100%);
    margin: 40px 0;
    position: relative;
}

.HomeCEGImgDiv {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 100%;
    max-height: calc(100% - 40px);
}

.HomeCEGImgDiv>img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.homeCEGContent {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    /* gap: 72px; */
    text-align: center;
    /* max-width: 520px; */
}

.CEGText{
    font-size: 48px;
    color: white;
    line-height: 60px;
}


.iconCEG{
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 68px);
}
.iconCEG>img{
    width: 150px;
}

@media only screen and (max-width: 768px) {
    .CEG {
        padding: 80px 20px 0px;
        min-height: unset;
    }
    
    .CEGText{
        font-size: 32px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 640px){
    .CEG {
        grid-template-columns: 1fr;
        row-gap: 72px;
        padding: 80px 20px 0px;
        min-height: unset;
        max-height: calc(100%);
    }
    
    .HomeCEGImgDiv {
        max-height: unset;
        max-width: 300px;
        margin: 0 auto;
    }
    
    .homeCEGContent {
        display: flex;
        justify-content:center;
        align-items: center;
        max-width: unset;
        text-align: center;
    }
    
    .CEGText{
        font-size: 32px;
        line-height: 40px;
    }
    
    .iconCEG{
        display: none;
    }
}