.Bespoke {
    display: flex;
    padding: 48px 72px 80px;
    gap: 40px;
    flex-direction: column;
}

.bespokeCard {
    display: grid;
    color: white;
    text-align: left;
    grid-template-columns: minmax(auto, 500px) 200px;
    justify-content: space-evenly;
    gap: 24px;
    margin-bottom: 24px;
}

.bespokeHead {
    font-family: 'heading';
    font-size: 24px;
}

.bespokeText {
    font-size: 18px;
    margin-top: 16px;
}

.bespokeCard.Invert {
    grid-template-columns: 220px minmax(auto, 500px);
}

.bespokeCardImg {
    width: 100%;
    height: 100%;
}

.bespokeCardImg img {
    object-fit: contain;
    height: 100%;
    max-width: 100%;
}

@media only screen and (max-width: 800px) {

    .Bespoke {
        padding: 48px 40px 80px;
    }

    .bespokeCard {
        grid-template-columns: minmax(auto, 500px) 150px;
    }

    .bespokeText {
        font-size: 16px;
    }

    .bespokeCard.Invert {
        grid-template-columns: 200px minmax(auto, 500px);
    }

    .bespokeCardImg {
        width: 100%;
        /* height: 100%; */
    }

    .bespokeCardImg img {
        object-fit: contain;
        margin: auto 0;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .Bespoke {
        padding: 48px 20px 80px;
        gap: 40px;
    }

    .bespokeCard {
        grid-template-columns: 1fr;
        justify-content: space-evenly;
        gap: 24px;
        margin-bottom: 24px;
    }

    .bespokeHead {
        font-family: 'heading';
        font-size: 24px;
    }

    .bespokeText {
        font-size: 16px;
        margin-top: 16px;
    }

    .bespokeCard.Invert {
        grid-template-columns: 1fr;
    }

    .bespokeCard.Invert .bespokeCardImg {
        order: 2;
    }

    .bespokeCardImg {
        width: 100%;
        height: 100%;
    }

    .bespokeCardImg img {
        object-fit: contain;
        height: 100%;
        /* width: 100%; */
        max-width: 280px;
        margin: 0 auto;
    }
}