.KnowMoreAboutUs{
    display: flex;
    padding: 48px 0 80px;
    gap: 40px;
    flex-direction: column;
}

.AboutUsGrid{
    /* flex gap-8 p-16 h-[90vh] */
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 5%;
    padding-left: 72px;
    padding-right: 0px;
}

.AboutUsImgContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 80vh;
}

.AboutUsImgContainer>img{
    /* object-cover object-center max-h-[100%] */
    object-fit: cover;
    object-position: center;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}

@media only screen and (orientation: portrait){
    .KnowMoreAboutUs{
        padding: 48px 0 80px;
        gap: 40px;
    }
    
    .AboutUsGrid{
        grid-template-columns: 1fr;
        align-items: center;
        gap: 32px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .AboutUsImgContainer{
        justify-content: center;
        height: 380px;
    }
}