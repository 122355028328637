body {
  background-color: #1A1816;
}

.App {
  text-align: center;
  color: var(--primary);
  font-family: 'body';
}

button {
  border-radius: 30px;
  padding: 0 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--black);
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

option {
  background-color: black;
}

.swiper-pagination-bullet {
  /* background-color: var(--primary) !important; */
  border: 0.5px solid white;
}

.swiper-pagination-bullet-active {
  background-color: var(--primary) !important;
  border: 0.5px solid var(--primary);
}


/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--black);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}



@media only screen and (min-width: 1280px) {
  .App>* {
    max-width: 1380px;
    margin: 0 auto;
  }

  .App>.FooterContainer {
    max-width: unset;
    margin: 0;
  }
}