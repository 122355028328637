.About {
    display: grid;
    grid-template-columns: 90fr 95fr;
    column-gap: 32px;
    padding: 80px 72px 0px;
    min-height: calc(100vh);
    max-height: calc(100%);
    /* margin: 40px 0; */
}

.HomeAboutImgDiv {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 100%;
    max-height: calc(100% - 40px);
}

.HomeAboutImgDiv>img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.homeAboutContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    max-width: 380px;
    place-self: center;
    text-align: left;
}

.aboutText{
    /* text-[60px] bg-[var(--primary)] text-white line-height- */
    font-size: 48px;
    color: white;
    line-height: 60px;
}

.aboutSubText{
    font-size: 14px;
    color: white;
}

@media only screen and (orientation: portrait){
    .About {
        padding: 80px 20px 0px;
        min-height: unset;
    }
    .aboutText{
        line-height: 40px;
        font-size: 32px;
    }
}


@media only screen and (max-width: 640px){
    .About {
        grid-template-columns: 1fr;
        row-gap: 72px;
        padding: 80px 20px 0px;
        min-height: unset;
        max-height: calc(100%);
    }
    
    .HomeAboutImgDiv {
        max-height: unset;
        order: 1;
        max-width: 300px;
        margin: 0 auto;
    }
    
    .homeAboutContent {
        gap: 32px;
        max-width: 360px;
        text-align: center;
        order: 2;
    }
    
    .aboutText{
        font-size: 32px;
        line-height: 40px;
    }
    
    .aboutSubText{
        font-size: 14px;
        text-align: center;
    }
}