.Contact {
    padding: 40px 72px 80px;
}

.ContactMain {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    align-items: center;
}

.contactContent, .contactContentInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    text-align: left;
}

@media only screen and (max-width:780px) {
    .Contact {
        padding: 40px 20px 80px;
    }
    .contactContent {
        gap: 40px;
    }
}

@media only screen and (max-width:640px) {
    .Contact {
        padding: 40px 20px 80px;
    }

    .ContactMain {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 32px;
        align-items: center;
    }

    .contactContent {
        flex-direction: row;
        gap: 40px;
        text-align: center;
        order: 2;
        align-items: flex-start;
        justify-content: space-between;
    }
    .contactContentInner{
        text-align: center;
    }
}